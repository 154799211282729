import { Swiper, SwiperSlide } from 'swiper/react';
import PropTypes from 'prop-types';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import ReactPlayer from 'react-player';
import { useState } from 'react';
import '../assets/scss/pages/SlideShow.scss';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

const SlideShow = ({ projects }) => {
	const [isHovered, setIsHovered] = useState(false);
	const [open, setOpen] = useState(false);
	const [index, setIndex] = useState(0);

	const images = projects.flatMap((project) =>
		project.contents
			.filter((content) => content.type === 'image')
			.map((content) => ({ src: content.content }))
	);

	const handleImageClick = (imageUrl) => {
		const imageIndex = images.findIndex((img) => img.src === imageUrl);
		setIndex(imageIndex);
		setOpen(true);
	};

	return (
		<div className='slide-show'>
			<Swiper
				modules={[Navigation, Pagination, Autoplay]}
				spaceBetween={10}
				slidesPerView={1}
				loop={true}
				className='custom-swiper'
				pagination={{
					clickable: true,
					el: '.custom-pagination',
				}}
			>
				{projects.map((project, indexProject) => (
					<SwiperSlide key={indexProject}>
						<h2 className='subtitle'>{project.title}</h2>
						<div className='contents'>
							{project.contents.map((content, indexContent) => {
								return (
									<div
										key={indexContent}
										className={`content-item__${content.type}`}
									>
										{content.type === 'image' ? (
											<img
												src={`${content.content}`}
												alt={content.title}
												className='image'
												onClick={() =>
													handleImageClick(
														content.content
													)
												}
												style={{ cursor: 'pointer' }}
											/>
										) : (
											<ReactPlayer
												url={content.content}
												controls={true}
												width='100%'
												height='100%'
												className='video'
												playing={
													isHovered === indexContent
												}
												onMouseEnter={() =>
													setIsHovered(indexContent)
												}
												onMouseLeave={() =>
													setIsHovered(null)
												}
											/>
										)}
									</div>
								);
							})}
						</div>
					</SwiperSlide>
				))}
			</Swiper>
			<div className='custom-pagination'></div>
			{open && (
				<Lightbox
					slides={images}
					open={open}
					index={index}
					close={() => setOpen(false)}
				/>
			)}
		</div>
	);
};

SlideShow.propTypes = {
	projects: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			contents: PropTypes.arrayOf(
				PropTypes.shape({
					type: PropTypes.string.isRequired,
					content: PropTypes.string.isRequired,
					title: PropTypes.string,
				})
			).isRequired,
		})
	).isRequired,
};

export default SlideShow;
