import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Home, Hero, Gallery, Works } from './pages';
import './assets/scss/index.scss';
import 'animate.css';
import 'aos/dist/aos.css';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Home />,
	},
	// {
	// 	path: '/gallery',
	// 	element: <Gallery />,
	// },
]);

function App() {
	return <RouterProvider router={router} />;
}

export default App;
