import '../assets/scss/pages/Works.scss';
import { useEffect, useState } from 'react';
import SlideShow from '../components/SlideShow';

const Works = () => {
	const [contents, setContents] = useState([]);

	const fetchContent = async () => {
		try {
			const response = await fetch(
				'https://rikiiinsadmin.web.id/api/projects/'
			);
			const data = await response.json();
			setContents(data);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		fetchContent();
	}, []);

	return (
		<section className='works'>
			<h1 className='title'>Works</h1>
			<div className='gallery'>
				{contents.length > 0 ? (
					<SlideShow projects={contents} />
				) : (
					<div className='loader' />
				)}
			</div>
		</section>
	);
};
export default Works;
