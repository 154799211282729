import { useState, useEffect } from 'react';
import '../assets/scss/pages/Hero.scss';
import profile from '../img/profile.png';
import profile2 from '../img/profile2.png';
import AOS from 'aos';

const Hero = () => {
	const [scrollingDown, setScrollingDown] = useState(true);
	const [isBouncing, setIsBouncing] = useState(false);

	const handleScroll = () => {
		const heroSectionHeight = document.querySelector('.hero').offsetHeight;

		setIsBouncing(true);
		setTimeout(() => setIsBouncing(false), 1500);

		if (scrollingDown) {
			window.scrollTo({
				top: heroSectionHeight,
				behavior: 'smooth',
			});
		} else {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}

		setScrollingDown(!scrollingDown);
	};

	useEffect(() => {
		AOS.init({
			duration: 2000,
			once: false,
		});
	}, []);

	useEffect(() => {
		const handleScrollEvent = () => {
			const heroSectionHeight =
				document.querySelector('.hero').offsetHeight;
			const scrollPosition = window.scrollY;

			if (scrollPosition >= heroSectionHeight) {
				setScrollingDown(false);
			} else {
				setScrollingDown(true);
			}
		};
		window.addEventListener('scroll', handleScrollEvent);

		return () => {
			window.removeEventListener('scroll', handleScrollEvent);
		};
	}, []);

	return (
		<section className='hero'>
			<div
				className='name__profile'
				data-aos='fade-down'
				data-aos-offset="200"
			>
				<h1 className='name'>Riki Noval Suherman</h1>
			</div>

			<div className='banner__profile'>
				<div
					className='banner__profile--left'
					data-aos='fade-left'
					data-aos-offset="200"
				>
					<h2 className='motto'>
						"Your Vision, My Lens, One Powerful Story."
					</h2>
				</div>

				<div className='banner__profile--middle'>
					<div
						className='photo-profile animate__animated animate__fadeIn'
						// data-aos='zoom-in'
						// data-aos-offset="10"
					>
						<img src={profile2} alt='Riki Noval Suherman' />
					</div>
				</div>

				<div
					className='banner__profile--right'
					data-aos='fade-right'
					data-aos-offset="10"
				>
					<h2 className='navigation'>
						Scroll Down to Explore My Creative Journey.
					</h2>

					<div className='contact-icon'>
						<span>
							<a
								href='https://www.instagram.com/rikiiiiins/'
								target='_blank'
								rel='noreferrer'
							>
								<i className='fa-brands fa-instagram fa-2xl'></i>
							</a>
						</span>
						<span>
							<a
								href='https://www.tiktok.com/@rikinovalsuherman/'
								target='_blank'
								rel='noreferrer'
							>
								<i className='fa-brands fa-tiktok fa-2xl'></i>
							</a>
						</span>
						<span>
							<a
								href='https://wa.me/6285951438257'
								target='_blank'
								rel='noreferrer'
							>
								<i className='fa-brands fa-whatsapp fa-2xl'></i>
							</a>
						</span>
					</div>
				</div>
			</div>

			<div className='scroll__down'>
				<span
					className={`scroll__down--button animate__animated ${isBouncing ? 'animate__bounce' : ''}`}
					onClick={handleScroll}
				>
					<i
						className={`fa-solid ${
							scrollingDown ? 'fa-arrow-down' : 'fa-arrow-up'
						} fa-2xl`}
					></i>
				</span>
			</div>
		</section>
	);
};
export default Hero;
