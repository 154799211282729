import '@fancyapps/ui/dist/fancybox/fancybox.css';
import { Hero, Works } from '../pages';

function App() {
	return (
		<>
			<Hero />
			<Works />
		</>
	);
}

export default App;
